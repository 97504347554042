<script setup lang="ts">
	import { useMenuStore } from "@/store/menuStore";
	import { useUserStore } from "@/store/userStore";
	import { useCommonCodeStore } from "@/store/commonCodeStore";
	import { useEquipStore } from "@/store/equipStore";
  import { UserType } from "@/enums";
	import Menu from "../components/Menu.vue";

	const menuStore = useMenuStore();
	const userStore = useUserStore();
	const equipStore = useEquipStore();
	const commonCodeStore = useCommonCodeStore();

	const selectMainMenu = computed(() => menuStore.SelectMainMenu);            // 선택메뉴
	const userSeq = computed(() => userStore.loginUserInfo?.userSeq as number)  // 사용자 NUM
  const userType = computed(() => userStore.getUserType);                     // 사용자 타입
	const routerName = computed(() =>	commonCodeStore.getEngSourceName(equipStore.selectEquipInfo.engSource as string));


  onBeforeMount(() => {
    // 선택 메뉴 표시
    menuStore.initSelectMenu(userType.value);
  });

</script>

<template>
	<div id="navigation">
		<ul class="fott" v-if="userType !== UserType.Manager && userType !== UserType.Admin" >
			<NuxtLink :to="`/mobile/user/${userSeq}/views/monitoring/${routerName}`" :custom="true" target="_blank" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('monitoring')">
					<button @click="navigate" class="monitor"><span>모니터링</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/equipInfo/owner/${routerName}`" :custom="true" target="_blank" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('equipInfo')">
					<button @click="navigate" class="info"><span>설비정보</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/powerGeneration/${routerName}`" :custom="true" target="_blank" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('powerGeneration')">
					<button @click="navigate" class="amount"><span>발전량</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/report/${routerName}`" :custom="true" target="_blank" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="{menuStore.setSelectMainMenu('report'); navigate;}">
					<button @click="navigate" class="report"><span>보고서</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/more/menu`" :custom="true" target="_blank" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('more')">
					<button @click="navigate" class="more"><span>더보기</span></button>
				</li>
			</NuxtLink>
		</ul>
		<ul v-else>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/equipInfo/company/${routerName}/InstruInfo`" :custom="true" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('InstruInfo')">
					<button @click="navigate" class="monitor"><span>계측정보</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/equipInfo/company/${routerName}/SpecInfo`" :custom="true" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('SpecInfo')">
					<button @click="navigate" class="info"><span>설비제원</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/event/all`" :custom="true" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('event')">
					<button @click="navigate" class="report"><span>이벤트</span></button>
				</li>
			</NuxtLink>
			<NuxtLink :to="`/mobile/user/${userSeq}/views/powerGeneration/${routerName}`" :custom="true" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('powerGeneration')">
					<button @click="navigate" class="amount"><span>발전량</span></button>
				</li>
			</NuxtLink>
			<NuxtLink  :to="`/mobile/user/${userSeq}/views/more/menu`" :custom="true" activeClass="on" v-slot="{ route, navigate }">
				<li class="depth-1" :class="route.fullPath.includes(selectMainMenu) ? 'on' : ''" @click="menuStore.setSelectMainMenu('more')">
					<button @click="navigate" class="more"><span>더보기</span></button>
				</li>
			</NuxtLink>
		</ul>
	</div>
</template>

<style>
.fott {
	height: 60px !important;
}
#navigation {
	user-select: none;
}

#navigation > button {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 35px;
    background-position: 50% 14px;
    background-repeat: no-repeat;
    text-align: center;
}
</style>
